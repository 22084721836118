<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Regionen">
      <template #actions>
        <el-button type="primary" icon="fal fa-plus" @click="newRegion">
          Neue Region
        </el-button>
      </template>
      <el-table
        v-loading="loading"
        style="width: 100%;"
        :data="regions"
        stripe
        :default-sort="{ prop: 'id', order: 'ascending' }"
      >
        <el-table-column width="70" prop="id" label="ID" sortable>
        </el-table-column>
        <el-table-column prop="name" label="Name" sortable> </el-table-column>
        <el-table-column label="Live">
          <template #default="scope">
            <el-tag
              v-if="scope.row.app_host.includes('familienbildung.app')"
              type="warning"
              :closable="false"
            >
              Nein
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="state.name" label="Bundesland" sortable>
        </el-table-column>
        <el-table-column prop="district.name" label="Regierungsbezirk" sortable>
        </el-table-column>
        <el-table-column prop="num_users" label="Nutzer"> </el-table-column>
        <el-table-column label="Aktionen" width="260">
          <template #default="scope">
            <el-button
              icon="fal fa-edit"
              size="mini"
              @click="handleEdit(scope.row)"
            >
              Bearbeiten
            </el-button>
            <delete-button
              v-slot="slotProps"
              :on-delete="deleteRegion"
              subject="Region"
            >
              <el-button
                size="mini"
                icon="fal fa-trash-alt"
                type="danger"
                @click="slotProps.handleDelete(scope.row)"
              >
                Löschen
              </el-button>
            </delete-button>
          </template>
        </el-table-column>
      </el-table>
    </page-content>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import DeleteButton from "@/components/DeleteButton.js"
import RegionsRepository from "@/repositories/super_admin/regions_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  metaInfo: {
    title: "Regionen"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    DeleteButton
  },
  mixins: [ApiErrorHandlerMixin],
  data() {
    return {
      loading: true,
      regions: []
    }
  },
  async created() {
    try {
      this.regions = await RegionsRepository.getRegions()
      this.loading = false
    } catch (error) {
      this.loading = false
      this.handleApiError(error)
    }
  },
  methods: {
    handleEdit(region) {
      this.$router.push({ name: "EditRegionPage", params: { id: region.id } })
    },
    async deleteRegion(region) {
      this.regions.splice(this.regions.indexOf(region), 1)
      await RegionsRepository.destroy(region.id)
    },
    newRegion() {
      this.$router.push({ name: "NewRegionPage" })
    }
  }
}
</script>
